@media only screen and (min-width: 1200px) {
  .App {
    background-color: #171821;
    display:flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    width:100%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:16px;
  }

  .Header {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .RJA-logo {
    width:15%;
    margin-top:2%;
  }

  .Nav {
    width:100%;
    margin-top:2%;
    display:flex;
    flex-direction:row;
  }

  .Nav-butn {
    width:25%;
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
  }

  .Nav-butn:hover {
    
    
    .Nav-titles {
      font-style:italic;
      text-transform: uppercase;
    }
  }

  .Nav-titles {
    color:#171821;
    font-size:1rem;
  }

  /* Home */

  .Home-page{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Home-title{
    font-family:Georgia, 'Times New Roman', Times, serif;
    text-decoration: underline;
    color:white;
    width:50%;
    text-align: center;
  }

  .Home-txt{
    width:80%;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
  }

  .Quote-gallery{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:5%;
  }

  .Active-quote{
    width:50%;
    border-radius: 10px;
  }

  .Gallery-nav {
    display:flex;
    flex-direction:row;
    width:30%;
    justify-content: space-evenly;
    margin-top:1%;
    color:white;
  }

  .Gallery-butn{
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
    padding:1% 10% 1% 10%;
    font-size:1rem;
    text-transform: uppercase;
  }

  .Gallery-butn:disabled {
    background:#171821;
    color:gold;
    border-color:gold;
    cursor:not-allowed;
  }

  .QuoteNum {
    font-size:1rem;
    margin-left:5%;
    margin-right:5%;
  }

  /* Community */

  .Community-page{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
  }

  .Social-clickable{
    text-decoration: none;
  }

  .Social-clickable:hover{
    text-decoration: none;
    scale:1.05;
  }

  .Reddit-butn {
    background:#171821;
    background: linear-gradient(90deg, #c77211 0%, #eeae64 46%, #c77211 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Discord-butn {
    background:#171821;
    background: linear-gradient(90deg, #1a1a1b 0%, #696662 46%, #1a1a1b 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Facebook-butn {
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #c3e0e9 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Bluesky-butn{
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #033646 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Youtube-butn{
    background:#171821;
    background: linear-gradient(90deg, red 0%, rgb(255, 200, 200) 46%, red 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Social-butns {
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:2%;
  }

  .Logo-in-butn {
    width:15%;
  }

  .Social-in-butn {
    width:10%;
  }

  .Call-text{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Call-to-action{
    font-size:1.75rem;
    letter-spacing:5px;
    color:white;
    text-align:center;
  }

  .Call-small-txt {
    font-size:1.25rem;
    letter-spacing:5px;
  }

  /* Library */

  .Library-page {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Select-category {
    width:30%;
    text-align:center;
    font-size:1.5rem;
    background-color:#171821;
    color:white;
    font-weight:600;
    border: 2px solid white;
    border-radius:10px;
    padding:5px;
    margin-top:2%;
    margin-bottom:2%;
  }

  .Library-book-div {
    width:80%;
    display:flex;
    flex-wrap: wrap;
  }

  .Book-item {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:2%;
    flex-basis: 50%;
    box-sizing: border-box;
  }

  .Book-image {
    width:50%;
  }

  .Book-title {
    color:white;
    font-size:1.5rem;
    margin-bottom:1%;
    width:50%;
    text-wrap:inherit;
    hyphens:auto;
    text-align:center;
  }

  .Book-link {
    margin-top:0;
    font-size:1.25rem;
    color:#C9BD36;
  }

  /* Get Started */

  .GetStarted-page {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .Get-started-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:italic;
    text-decoration: underline;
  }

  .Tablet-pic {
    width:20%;
    border-radius: 20px;
    margin-top:1%;
  }

  .Get-started-text {
    font-size:1rem;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
    font-weight:600;
    width:80%;
  }

  .Section-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
}

@media only screen and (min-width: 750px) and (max-width:1199px ) {
  .App {
    background-color: #171821;
    display:flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    width:100%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:16px;
  }

  .Header {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .RJA-logo {
    width:15%;
    margin-top:2%;
  }

  .Nav {
    width:100%;
    margin-top:2%;
    display:flex;
    flex-direction:row;
  }

  .Nav-butn {
    width:25%;
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
  }

  .Nav-butn:hover {
    
    
    .Nav-titles {
      font-style:italic;
      text-transform: uppercase;
    }
  }

  .Nav-titles {
    color:#171821;
    font-size:1rem;
  }

  /* Home */

  .Home-page{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Home-title{
    font-family:Georgia, 'Times New Roman', Times, serif;
    text-decoration: underline;
    color:white;
    width:50%;
    text-align: center;
  }

  .Home-txt{
    width:80%;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
  }

  .Quote-gallery{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:5%;
  }

  .Active-quote{
    width:50%;
    border-radius: 10px;
  }

  .Gallery-nav {
    display:flex;
    flex-direction:row;
    width:30%;
    justify-content: space-evenly;
    margin-top:1%;
    color:white;
  }

  .Gallery-butn{
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
    padding:1% 10% 1% 10%;
    font-size:1rem;
    text-transform: uppercase;
  }

  .Gallery-butn:disabled {
    background:#171821;
    color:gold;
    border-color:gold;
    cursor:not-allowed;
  }

  .QuoteNum {
    font-size:1rem;
    margin-left:5%;
    margin-right:5%;
  }

  /* Community */

  .Community-page{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
  }

  .Social-clickable{
    text-decoration: none;
  }

  .Social-clickable:hover{
    text-decoration: none;
    scale:1.05;
  }

  .Reddit-butn {
    background:#171821;
    background: linear-gradient(90deg, #c77211 0%, #eeae64 46%, #c77211 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Discord-butn {
    background:#171821;
    background: linear-gradient(90deg, #1a1a1b 0%, #696662 46%, #1a1a1b 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Facebook-butn {
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #c3e0e9 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Bluesky-butn{
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #033646 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Youtube-butn{
    background:#171821;
    background: linear-gradient(90deg, red 0%, rgb(255, 200, 200) 46%, red 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Social-butns {
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:2%;
  }

  .Logo-in-butn {
    width:15%;
  }

  .Social-in-butn {
    width:10%;
  }

  .Call-text{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Call-to-action{
    font-size:1.75rem;
    letter-spacing:5px;
    color:white;
    text-align:center;
  }

  .Call-small-txt {
    font-size:1.25rem;
    letter-spacing:5px;
  }

  /* Library */

  .Library-page {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Select-category {
    width:30%;
    text-align:center;
    font-size:1.5rem;
    background-color:#171821;
    color:white;
    font-weight:600;
    border: 2px solid white;
    border-radius:10px;
    padding:5px;
    margin-top:2%;
    margin-bottom:2%;
  }

  .Library-book-div {
    width:80%;
    display:flex;
    flex-wrap: wrap;
  }

  .Book-item {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:2%;
    flex-basis: 50%;
    box-sizing: border-box;
  }

  .Book-image {
    width:50%;
  }

  .Book-title {
    color:white;
    font-size:1.5rem;
    margin-bottom:1%;
    width:50%;
    text-wrap:inherit;
    hyphens:auto;
    text-align:center;
  }

  .Book-link {
    margin-top:0;
    font-size:1.25rem;
    color:#C9BD36;
  }

  /* Get Started */

  .GetStarted-page {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .Get-started-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:italic;
    text-decoration: underline;
  }

  .Tablet-pic {
    width:20%;
    border-radius: 20px;
    margin-top:1%;
  }

  .Get-started-text {
    font-size:1rem;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
    font-weight:600;
    width:80%;
  }

  .Section-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
}

@media only screen and (max-width: 750px) {
  .App {
    background-color: #171821;
    display:flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    width:100%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:16px;
  }

  .Header {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .RJA-logo {
    width:30%;
    margin-top:2%;
  }

  .Nav {
    width:100%;
    margin-top:2%;
    display:flex;
    flex-direction:row;
  }

  .Nav-butn {
    width:25%;
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
  }

  .Nav-butn:hover {
    
    
    .Nav-titles {
      font-style:italic;
      text-transform: uppercase;
    }
  }

  .Nav-titles {
    color:#171821;
    font-size:1rem;
  }

  /* Home */

  .Home-page{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Home-title{
    font-family:Georgia, 'Times New Roman', Times, serif;
    text-decoration: underline;
    color:white;
    width:50%;
    font-size:1.5rem;
    text-align: center;
  }

  .Home-txt{
    width:80%;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
  }

  .Quote-gallery{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:5%;
  }

  .Active-quote{
    width:70%;
    border-radius: 10px;
  }

  .Gallery-nav {
    display:flex;
    flex-direction:row;
    width:40%;
    justify-content: space-evenly;
    margin-top:1%;
    color:white;
  }

  .Gallery-butn{
    background: rgb(201,189,54);
    background: linear-gradient(90deg, rgba(201,189,54,1) 0%, rgba(229,248,88,1) 46%, rgba(201,189,54,1) 100%);;
    cursor:pointer;
    padding:1% 5% 1% 5%;
    font-size:1rem;
    text-transform: uppercase;
  }

  .Gallery-butn:disabled {
    background:#171821;
    color:gold;
    border-color:gold;
    cursor:not-allowed;
  }

  .QuoteNum {
    font-size:1rem;
    margin-left:5%;
    margin-right:5%;
  }

  /* Community */

  .Community-page{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
  }

  .Social-clickable{
    text-decoration: none;
  }

  .Social-clickable:hover{
    text-decoration: none;
    scale:1.05;
  }

  .Reddit-butn {
    background:#171821;
    background: linear-gradient(90deg, #c77211 0%, #eeae64 46%, #c77211 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Discord-butn {
    background:#171821;
    background: linear-gradient(90deg, #1a1a1b 0%, #696662 46%, #1a1a1b 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Facebook-butn {
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #c3e0e9 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Bluesky-butn{
    background:#171821;
    background: linear-gradient(90deg, #4bd9f1 0%, #033646 46%, #4bd9f1 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Youtube-butn{
    background:#171821;
    background: linear-gradient(90deg, red 0%, rgb(255, 200, 200) 46%, red 100%);
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: solid white 3px;
    margin-bottom:2%;
  }

  .Social-butns {
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:2%;
  }

  .Logo-in-butn {
    width:15%;
  }

  .Social-in-butn {
    width:10%;
  }

  .Call-text{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Call-to-action{
    font-size:1.75rem;
    letter-spacing:5px;
    color:white;
    text-align:center;
  }

  .Call-small-txt {
    font-size:1.25rem;
    letter-spacing:5px;
  }

  /* Library */

  .Library-page {
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .Select-category {
    width:30%;
    text-align:center;
    font-size:1.5rem;
    background-color:#171821;
    color:white;
    font-weight:600;
    border: 2px solid white;
    border-radius:10px;
    padding:5px;
    margin-top:2%;
    margin-bottom:5%;
  }

  .Library-book-div {
    width:80%;
    display:flex;
    flex-wrap: wrap;
  }

  .Book-item {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:5%;
    flex-basis: 100%;
    box-sizing: border-box;
  }

  .Book-image {
    width:70%;
  }

  .Book-title {
    color:white;
    font-size:1.5rem;
    margin-bottom:1%;
    width:70%;
    text-wrap:inherit;
    hyphens:auto;
    text-align:center;
  }

  .Book-link {
    margin-top:0;
    font-size:1.25rem;
    color:#C9BD36;
  }

  /* Get Started */

  .GetStarted-page {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }

  .Get-started-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style:italic;
    text-decoration: underline;
  }

  .Tablet-pic {
    width:20%;
    border-radius: 20px;
    margin-top:1%;
  }

  .Get-started-text {
    font-size:1rem;
    color:rgba(255, 255, 255, 0.788);
    text-wrap:initial;
    hyphens: auto;
    line-height:25px;
    font-weight:600;
    width:80%;
  }

  .Section-title {
    color:white;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
}